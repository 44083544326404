﻿@import '~swiper/swiper';
@import '~swiper/components/navigation/navigation';
@import '~swiper/components/pagination/pagination';
@import '~swiper/components/effect-fade/effect-fade';

.slider {
	position: relative;
	display: flex;
	flex-wrap: wrap;

	&.is-basic-slider {
		max-width: rem(1280px);
		padding: 0;
		margin: 0 auto;

		@include breakpoint(ts) {
			padding: 0 rem(68px);
		}

		@include breakpoint(ll) {
			padding: 0 rem(108px);
		}

		@include breakpoint(ds) {
			padding: 0 rem(148px);
		}

		.swiper-slide {
			padding: 0 rem(8px) rem(8px) 0;
		}

		.swiper-button-prev {
			left: 0;

			@include breakpoint(ts) {
				left: rem(20px);
			}
		}

		.swiper-button-next {
			right: 0;

			@include breakpoint(ts) {
				right: rem(20px);
			}
		}
	}

	&.is-categories-block {

		@include breakpoint(ts) {
			padding: 0 rem(22px);
		}

		@include breakpoint(ll) {
			padding: 0 rem(44px);
		}

		@include breakpoint(ds) {
			padding: 0 rem(88px);
		}

		.category-slide {
			background: #fff;

			@include breakpoint(l) {
				padding: 0 0 0 rem(200px);
			}
		}

		.swiper-pagination {

			@include breakpoint(l) {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}

			@include breakpoint(ds) {
				left: rem(48px);
			}
		}

		.sub-title {
			margin: 0;
		}

		.column.has-content {
			display: flex;
			align-items: center;
		}

		.swiper-slide {
			padding: 0;
		}
	}

	&.is-media-slider-items {
		max-width: rem(1240px);
	}
}

.swiper-container {

	.swiper-slide {
		height: auto;
		background: #fff;
	}
}

.swiper-pagination {
	@include font-title-button;
	position: relative;
	color: $color-primary;
	width: 100%;

	.swiper-pagination-bullet {
		position: relative;
		width: auto;
		height: auto;
		padding: rem(6px) rem(16px) rem(6px);
		background: none;
		opacity: 0.2;
		transition: opacity $transition-time ease-in-out;
		font-weight: bold;

		&:after {
			position: absolute;
			content: '';
			width: 100%;
			height: rem(2px);
			bottom: 0;
			left: 0;
			background: $color-primary;
		}

		&:hover {
			opacity: 1;
		}
	}

	.swiper-pagination-bullet-active {
		font-weight: bold;
		opacity: 1;

		&:after {
			height: rem(3px);
			background: $color-secondary;
		}
	}

	&.is-vertical-l {

		@include breakpoint(l) {
			display: flex;
			flex-direction: column;
			text-align: left;
			max-width: rem(200px);

			.swiper-pagination-bullet {
				padding: rem(9px) rem(16px) rem(15px) rem(25px);

				&:after {
					width: rem(2px);
					height: 100%;
					opacity: 0.2;
				}
			}

			.swiper-pagination-bullet-active {

				&:after {
					width: rem(4px);
					opacity: 1;
				}
			}
		}
	}
}

	.swiper-button-next,
	.swiper-button-prev {
		position: relative;
		width: 50%;
		height: rem(48px);
		margin-top: 0;
		top: rem(50px);
		background: rgba($color-primary, 0.2);
		transition: all $transition-time ease-in-out;

		@include breakpoint(ts) {
			position: absolute;
			width: rem(48px);
			height: rem(48px);
			border-radius: 50%;
			top: 50%;
			transform: translateY(-50%);
		}

		&:focus {
			background: rgba($color-primary, 0.75);
		}

		&:hover {
			background: rgba($color-primary, 1);
		}

		&:before {
			position: absolute;
			content: '';
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: rem(16px);
			color: #fff;
		}

		&:after {
			display: none;
		}

		&.swiper-button-disabled {
			opacity: 0;
		}
	}

	.swiper-button-next {
		right: 0;

		@include breakpoint(ts) {
			right: rem(24px);
		}

		@include breakpoint(ll) {
			right: rem(48px);
		}

		@include breakpoint(ds) {
			right: rem(96px);
		}

		&:before {
			@include icon($icon-arrow-right);
		}
	}

	.swiper-button-prev {
		left: 0;

		@include breakpoint(ts) {
			left: rem(24px);
		}

		@include breakpoint(ll) {
			left: rem(48px);
		}

		@include breakpoint(ds) {
			left: rem(96px);
		}

		&:before {
			@include icon($icon-arrow-left);
		}
	}
	/*



.highlighted-slider {
	position: relative;
}

.image-slideshow {
	margin: 0 auto;
	max-width: rem(800px);
	width: 100%;
	overflow: visible;

	.swiper-slide {
		opacity: 0.3;
		transition: opacity $transition-time ease-in-out;

		&.swiper-slide-active {
			opacity: 1;
		}
	}
}*/
