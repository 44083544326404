﻿.textarea {
	width: 100%;
	min-height: rem(242px);
	padding: rem(10px) rem(28px);
	border: 1px solid rgba($color-primary, 0.1);
	-moz-appearance: none;
	-webkit-appearance: none;
	resize: vertical;

	&:focus {
		border-color: rgba($color-primary, 0.25);
	}
}
