﻿.video-link {
	@include font-title-button;
	position: relative;
	display: inline-flex;
	padding: rem(19px) 0 rem(21px) rem(80px);
	color: $color-primary;
	font-weight: bold;
	letter-spacing: 0;

	&:before {
		content: '';
		position: absolute;
		width: rem(64px);
		height: rem(64px);
		border-radius: 50%;
		top: 0;
		left: 0;
		background: $color-secondary;
		transition: background $transition-time ease-in-out;
	}

	&:after {
		position: absolute;
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: rem(12px) 0 rem(12px) 16px;
		border-color: transparent transparent transparent #fff;
		top: rem(20px);
		left: rem(26px);
	}

	&:hover {

		&:before {
			background: $color-primary;
		}
	}
}
