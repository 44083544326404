﻿.nav-contact {
	padding: rem(43px) 0;

	.nav-contact__links {
		display: flex;
		flex-wrap: wrap;
		margin: 0 rem(-12px) rem(42px);

		&.has-large-margin {
			margin: 0 rem(-25px) rem(22px);

			.nav-contact__link {
				margin: 0 rem(25px);
			}
		}

		&.has-letter-spacing .nav-contact__link {
			letter-spacing: 0.05em;
		}
	}

	.nav-contact__link {
		position: relative;
		font-weight: bold;
		margin: 0 rem(12px);
		letter-spacing: 0;
		text-transform: uppercase;

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			font-size: rem(24px);
			color: $color-secondary;
		}

		&.is-telephone {
			padding: 0 0 0 rem(36px);

			&:after {
				@include icon($icon-phone);
			}
		}

		&.is-email {
			padding: 0 0 0 rem(36px);
			text-transform: none !important;

			&:after {
				@include icon($icon-mail);
			}
		}

		&.has-underline {
			@include underline-effect-base($color-primary, 1px);

			&:focus,
			&:hover {
				@include underline-effect-hover();
			}
		}

		&.has-color-hover {
			transition: color $transition-time ease-in-out;

			&:focus,
			&:hover {
				color: $color-secondary;
			}
		}
	}

	.languages {
		margin: 0 0 rem(57px);
	}

	ul.social-media {
		margin-left: -14px;
	}
}
