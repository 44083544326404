﻿.languages {
	position: relative;
	z-index: 1;
	display: inline-block;
	color: #fff;
	cursor: pointer;
	margin: 0;

	dt,
	dd {
		background: mix(#000, $color-primary, 15%);
	}

	dt {
		position: relative;
		padding: rem(15px) rem(50px) rem(15px) rem(64px);
		color: #fff;
		outline: none;
		cursor: pointer;
		border-radius: $border-radius $border-radius 0 0;

		@include breakpoint(lm) {
			border-radius: $border-radius;
		}

		&:after,
		&:before {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		&:before {
			@include icon($icon-globe);
			left: rem(32px);
			font-size: rem(24px);
			color: $color-secondary;
		}

		@include breakpoint(lm) {

			&:after {
				@include icon($icon-arrow-down);
				right: rem(32px);
				font-size: rem(16px);
			}
		}
	}

	dd {
		padding: 0.625rem 0;
		margin: 0;
		border-radius: 0 0 $border-radius $border-radius;
		overflow: hidden;
		width: 100%;
		top: calc(100% -1px);
		list-style: none;
		visibility: visible;

		@include breakpoint(lm) {
			visibility: hidden;
			position: absolute;
		}
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	li:last-child a {
		border: none;
	}

	a {
		display: block;
		padding: 0.125rem 2rem;
		text-decoration: none;
		transition: transform $transition-time ease-in-out;
		border: none;

		&:hover,
		&:focus {
			//text-decoration: underline;
		}
	}

	@include breakpoint(lm) {

		&:not(.clicked):focus-within,
		&.clicked.open {

			dt,
			dt:focus {
				border-radius: $border-radius $border-radius 0 0;

				&::after {
					content: $icon-arrow-down;
				}
			}

			dd {
				visibility: visible;
			}
		}
	}
}
