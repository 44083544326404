﻿.form {
	width: 100%;
	overflow: hidden;

	form {
		width: 100%;
	}

	.label,
	.form-label {
		display: block;
		margin: 0 0 rem(5px);
		color: $color-primary;
		font-weight: bold;
	}

	.input,
	.select,
	.longanswer,
	.form-cell input {
		margin: 0 0 rem(20px);
	}

	.form-button-container {
		overflow: hidden;

		.button {
			float: right;
		}
	}

	.checkbox,
	.dataconsent,
	.radio {
		margin: rem(49px) 0 rem(20px);

		label {
			@include font-text;
			color: $color-text;
			font-weight: normal;
			margin: 0;
		}
	}


	.email2 {
		display: none;
	}

	.submitButtonClass .submit {
		@extend .button;
	}
}
