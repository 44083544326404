﻿.login-link {
	position: relative;
	width: 50%;
	height: rem(64px);
	background: $color-secondary;
	text-indent: -9999px;
	overflow: hidden;
	color: #fff;
	transition: background $transition-time ease-in-out;

	@include breakpoint(l) {
		width: rem(64px);
		height: rem(64px);
		border-radius: 50%;
		box-shadow: 0px rem(5px) rem(10px) 0px rgba(0, 0, 0, 0.2);
	}

	&:before {
		@include icon($icon-login);
		position: absolute;
		top: 50%;
		left: 50%;
		text-indent: 0;
		font-size: rem(24px);
		transform: translate(-50%, -50%);
	}

	&:hover {
		background: mix($color-secondary, $color-primary);
	}
}
