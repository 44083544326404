﻿.title {
	@include font-title;
	color: $color-primary;
	text-transform: uppercase;
	font-weight: normal;

	span {
		display: block;
	}

	&.is-small {
		@include font-title-button;
		font-weight: bold;
	}

	&.is-smaller {
		@include font-title-smaller;
	}

	&.is-bigger {
		@include font-title-big;
	}

	&.has-decoration {

		strong {
			display: inline-block;
			position: relative;

			&:before {
				position: absolute;
				content: '';
				width: calc(100% + 1rem);
				height: rem(2px);
				bottom: rem(-4px);
				left: rem(-4px);
				background: $color-secondary;
			}
		}
	}
}

.sub-title {
	@include font-sub-title;
	color: $color-primary;
}