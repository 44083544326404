﻿.media-slider-item {
	position: relative;
	z-index: 0;
	background: $color-secondary url(../../images/background-element-white.svg);
	background-size: rem(45px) rem(74px);
	color: #fff;
	text-align: left;
	margin-left: rem(20px);

	.sub-title {
		margin: 0 0 rem(18px);
		text-transform: uppercase;
		color: #fff;
	}

	.media-slider-item__content {
		padding: rem(42px) rem(48px) rem(23px);

		.content {
			max-width: unset;
			margin: 0;
		}
	}

	&:before,
	&:after {
		position: absolute;
		z-index: -1;
		content: '';
		background: $color-border;
	}

	&:before {
		width: rem(8px);
		height: 100%;
		top: rem(8px);
		right: rem(-8px);
	}

	&:after {
		width: 100%;
		height: rem(8px);
		bottom: rem(-8px);
		left: rem(8px);
	}
}
