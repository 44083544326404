.toast {
	@include font-title-button;
	position: fixed;
	z-index: 100;
	top: rem(148px) !important;
	right: rem(20px);
	width: calc(100% - 2.5rem);
	padding: rem(25px) rem(48px) rem(40px);
	border-radius: rem(8px);
	background: #fff;
	color: $color-primary;
	cursor: pointer;
	opacity: 0;
	transform: translateY(-15px);
	transition: right 600ms ease-in-out, opacity 600ms ease-in-out;
	overflow: hidden;

	@include breakpoint(l) {
		width: 100%;
		max-width: rem(370px);
		top: rem(48px) !important;
		right: rem(48px);
	}

	&.on {
		opacity: 0.95;

		@include breakpoint(l) {
			opacity: 1;
		}

		&::after {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 100%;
			height: rem(4px);
			animation: width 8000ms;
			background: $color-secondary;
			content: '';
		}

		&:hover,
		&:focus {

			&::after {
				animation: nonexistent; // Setting the animation to a nonexistent animation resets it
			}
		}

		&:hover,
		&:focus {
			&::after {
				animation: nonexistent; // Setting the animation to a nonexistent animation resets it
			}
		}
	}

	.title {
		margin: 0;
		font-weight: normal;
		text-transform: uppercase;
	}

	.caption {
		@include font-title-caption;
		color: $color-secondary;
		text-transform: uppercase;
	}

	.event-name {
		font-weight: bold;
	}

	.toast-close {
		position: absolute;
		color: $color-secondary;
		top: 0.5rem;
		right: 1rem;

		@include breakpoint(l) {
			top: 0.375rem;
			right: 0.375rem;
		}
	}
}

@keyframes width {
	0% {
		width: 100%;
	}

	100% {
		width: 0;
	}
}
