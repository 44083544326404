﻿.events {

	.event:last-child {
		border-bottom: 1px solid rgba($color-primary, 0.1);
	}
}

.event {
	border-top: 1px solid rgba($color-primary, 0.1);
	padding: 0 0 rem(24px);

	@include breakpoint(t) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 0 0 rem(4px);
	}

	.button {
		margin: 0 0 0 auto;
	}

	.event__content {
		padding: rem(17px) 0 rem(18px);
	}

	.event__date {
		@include font-title-smaller;
		color: $color-special;
	}

	.event__title {
		@include font-title-button;
		color: $color-primary;
		margin: 0;
	}
}
