﻿@import "breakpoints";

// Gutter creation mixin
@mixin columns-gutter($size) {
	$size-half: ($size / 2 * 1px);

	.columns.has-gutter-#{$size} {
		margin-left: ($size-half * -1);
		margin-right: ($size-half * -1);

		.column {
			border-color: transparent;
			border-style: solid;
			border-top: 0;
			border-right-width: ($size-half);
			border-bottom: 0;
			border-left-width: ($size-half);
		}
	}
}

// Columns
.columns {
	display: flex;
	flex-wrap: wrap;

	&.has-no-wrap {
		overflow: hidden;
		flex-wrap: nowrap;
	}
}

$column-sizes:
	80 (100% / 5 * 4),
	75 (100% / 4 * 3),
	66 (100% / 3 * 2),
	60 (100% / 5 * 3),
	50 (100% / 2),
	40 (100% / 5 * 2),
	33 (100% / 3),
	25 (100% / 4),
	20 (100% / 5),
	16 (100% / 6)
	10 (100% / 10) !default;

.column {
	width: 100%;
	flex-shrink: 0;

	@each $size-name, $size in $column-sizes {

		&.is-#{$size-name} {
			width: $size;
		}
	}

	@each $name, $name-short, $pixels in $breakpoints {

		@each $size-name, $size in $column-sizes {

			&.is-#{$size-name}-#{$name-short} {

				@include breakpoint($name-short) {
					width: $size;
				}
			}
		}
	}
}