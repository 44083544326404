﻿.announcement {
	@include font-title-button;
	position: relative;
	z-index: 0;
	display: block;
	width: 100%;
	padding: rem(23px) 0 rem(25px);
	text-align: center;
	background: $color-primary;
	color: #fff;
	font-weight: bold;
	transition: background $transition-time ease-in-out;

	&:hover {
		background: mix($color-primary, $color-secondary, 50%);
	}

	~ main .header {

		@include breakpoint(l) {
			min-height: calc(100vh - 72px);
		}
	}
}
