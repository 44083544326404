﻿.legal {
	@include font-legal;
	position: relative;
	padding: rem(14px) 0 rem(15px);
	margin: 0 auto;
	overflow: hidden;
	color: #fff;
	opacity: 0.5;

	.container {
		margin: 0 auto;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		float: left;
		margin-right: rem(12px);
		padding-top: rem(3px);
		padding-bottom: rem(5px);
	}

	a {

		&:hover {
			text-decoration: underline;
		}
	}
}
