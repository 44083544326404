﻿.checkbox,
.dataconsent,
.radio {
	position: relative;
	text-align: left;

	input {
		position: absolute;
		margin: 0;
		top: rem(40px);
		left: rem(10px);
		opacity: 0;

		&:focus + label:before {
			border-color: rgba($color-primary, 0.25);
		}
	}

	label {
		@include font-text;
		color: $color-text;
		position: relative;
		display: block;
		padding: rem(12px) 0 rem(12px) rem(42px);
		margin: 0;
		cursor: pointer;
		margin: rem(0) 0 rem(0);

		&:before {
			content: '';
			position: absolute;
			width: rem(20px);
			height: rem(20px);
			border: 1px solid rgba($color-primary, 0.1);
			padding: rem(4px) 0 0;
			left: 0;
			text-align: center;
			background: #fff;
			transition: all $transition-time ease-in-out;
		}

		&:focus,
		&:hover {

			&:before {
				border-color: rgba($color-primary, 0.25);
			}
		}

		p {
			margin: 0;
		}

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.checkbox,
.dataconsent {

	input {

		&:checked + label {

			&:before {
				color: $color-primary;
			}
		}
	}

	label {

		&:before {
			@include icon($icon-check);
			font-size: rem(10px);
			color: transparent;
		}
	}
}

.radio {

	input {

		&:checked + label {

			&:after {
				opacity: 1;
			}
		}
	}

	label {

		&:before {
			border-radius: 100%;
		}

		&:after {
			position: absolute;
			content: '';
			width: rem(14px);
			height: rem(14px);
			border-radius: 100%;
			top: rem(20px);
			left: rem(3px);
			background: rgba($color-primary, 0.8);
			opacity: 0;
			transition: opacity $transition-time ease-in-out;
		}
	}
}

.checkbox,
.radio,
.dataconsent {

	label {

		&:before {
			top: rem(13px);
		}
	}
}

.radiobuttonlist {
	display: flex;
	flex-wrap: wrap;
	margin: 0 rem(-20px);

	.radio {
		margin: 0 rem(20px);
	}
}