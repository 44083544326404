﻿.logo {
	z-index: 101;
	display: block;
	width: rem(150px);
	height: rem(51px);
	text-indent: -9999px;
	background: url(../../images/logo-pylot.svg);
	background-size: cover;

	.home & {

		@include breakpoint(l) {
			background-image: url(../../images/logo-white.svg);
		}
	}

	@include breakpoint(ml) {
		width: rem(188px);
		height: rem(64px);
	}
}
