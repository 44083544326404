﻿.container {
	width: 100%;
	max-width: rem(1480px);
	padding: 0 rem(20px);

	&.has-slider {
		max-width: rem(1392px);
		margin: 0 auto;

		@include breakpoint(ts) {
			padding: 0 rem(100px);
		}

		.home & {
			max-width: rem(1640px);
		}
	}

	&.is-smallish {
		max-width: rem(1232px);
	}

	&.is-small {
		max-width: rem(984px);
	}

	&.is-smaller {
		max-width: rem(696px);
	}
}
