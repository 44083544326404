.testimonial {
	position: relative;
	z-index: 0;
	border: 1px solid #e5ebf0;
	padding: rem(7px) rem(20px) rem(47px);
	background: #fff;

	.image {
		max-width: rem(150px);
		margin: 0 auto;
	}

	.testimonial__content {
		margin: 0 auto;
	}

	.content {
		position: relative;
		@include font-quote;
		max-width: rem(792px);
		padding: 0 rem(48px);
		margin: 0 auto rem(40px);

		&:before,
		&:after {
			position: absolute;
			font-size: rem(24px);
			color: $color-border;
		}

		&:before {
			@include icon($icon-quote-left);
			top: 0;
			left: 0;
		}

		&:after {
			@include icon($icon-quote-right);
			bottom: 0;
			right: 0;
		}
	}

	.link {
		margin: 0 0 rem(46px);
	}

	.testimonial__person-name,
	.testimonial__person-title {
		display: block;
	}

	.testimonial__person-name {
		@include font-title-button;
		font-weight: bold;
	}

	&:before,
	&:after {
		position: absolute;
		z-index: -1;
		content: '';
		background: $color-border;
	}

	&:before {
		width: rem(8px);
		height: 100%;
		top: rem(8px);
		right: rem(-8px);
	}

	&:after {
		width: 100%;
		height: rem(8px);
		bottom: rem(-8px);
		left: rem(8px);
	}
}
