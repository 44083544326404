.header {
	position: relative;
	z-index: 0;

	@include breakpoint(l) {
		display: flex;
		align-items: center;
		min-height: 100vh;
	}

	.header-image__image,
	.header-image__video {
		z-index: -2;
		width: 100%;
		height: rem(400px);
		object-fit: cover;
		object-position: center;

		@include breakpoint(l) {
			position: absolute;
			width: 60%;
			height: 100%;
			top: 0;
			right: 0;

			.home & {
				width: 100%;
			}
		}
	}

	.header-image__video {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;

		@include breakpoint(l) {
			z-index: -1;
		}
	}

	.container {
		position: relative;
		z-index: 1;
		margin: 0 auto;
	}

	> .container {

		@include breakpoint(l) {

			&:after,
			&:before {
				position: absolute;
				content: '';
				width: rem(1px);
				left: rem(28px);
				background: $color-secondary;
				transition: opacity $transition-time ease-in-out;

				.home & {
					opacity: 0;
				}
			}

			&:before {
				height: rem(96px);
				bottom: rem(-213px);
				opacity: 0.5;
			}

			&:after {
				height: rem(48px);
				bottom: rem(-199px);
			}
		}
	}

	.header__content {
		max-width: rem(620px);
		padding: rem(39px) 0 rem(44px);

		@include breakpoint(l) {
			padding: rem(138px) 0 rem(143px);
		}

		.button {

			.home & {
			}
		}

		.video-link {

			.home & {

				@include breakpoint(l) {
					color: #fff;
				}
			}
		}
	}

	.content {
		max-width: rem(446px);
	}

	.title {
		margin: 0 0 rem(33px);
		transition: color $transition-time ease-in-out;

		@include breakpoint(l) {

			.home & {
				@include font-title-hero;
				color: #fff;
			}
		}
	}

	.header__page-name {
		@include font-title-big;
		width: 100%;
		position: absolute;
		z-index: 1;
		top: rem(200px);
		left: 0;
		transform: translateY(-100%);
		text-align: right;
		color: #fff;
		font-weight: bold;
		text-transform: uppercase;

		@include breakpoint(l) {
			top: auto;
			bottom: rem(54px);
			transform: none;
		}

		.button {
			text-transform: none;

			.home & {
				opacity: 0;
			}
		}

		.container {

			@include breakpoint(l) {
				padding: 0 rem(88px);
			}

			.home & {

				@include breakpoint(lm) {
					padding: 0 rem(140px);
				}

				@include breakpoint(ll) {
					padding: 0 rem(192px);
				}
			}
		}
	}

	.header__scroll {
		@include font-short-description;
		z-index: 1;
		position: absolute;
		left: 50%;
		bottom: rem(72px);
		transform: translateX(-50%);
		text-transform: uppercase;
		color: #fff;
		transition: opacity $transition-time ease-in-out, color $transition-time ease-in-out;
		display: none;

		&:after,
		&:before {
			position: absolute;
			content: '';
			width: rem(1px);
			left: rem(28px);
			background: #fff;
			transition: background $transition-time ease-in-out;
		}

		&:before {
			height: rem(96px);
			top: rem(-99px);
			opacity: 0.5;
		}

		&:after {
			height: rem(48px);
			top: rem(-68px);
			transition: top $transition-time ease-in-out, background $transition-time ease-in-out;
		}

		&:hover {
			color: $color-primary;

			&:after,
			&:before {
				background: $color-primary;
			}

			&:after {
				top: rem(-48px);
			}
		}

		@include breakpoint(l) {
			display: block;
		}
	}

	.header__label {
		display: none;
	}

	.header__page-name-container {

		@include breakpoint(l) {
			width: 50%;
			margin-left: auto;
		}
	}

	@include breakpoint(l) {

		&:before {
			position: absolute;
			content: '';
			z-index: 0;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: #fff;
			clip-path: polygon(0 0, 72% 0, 45% 100%, 0 100%);
			transition: all .5s ease-in-out;

			.home & {
				background: linear-gradient(to left, rgba(#fff, 0) 50%, #fff 50%);
				background-size: 200% 100%;
				background-position: right bottom;
			}
		}

		.header__label {
			position: absolute;
			display: block;
			z-index: 2;
			color: #fff;
			text-transform: uppercase;
			top: rem(367px);
			left: rem(20px);
			transform: rotate(-90deg);
			transform-origin: 0 0;
			transition: opacity $transition-time ease-in-out;

			&:after {
				position: absolute;
				content: '';
				width: rem(475px);
				height: 1px;
				background: #fff;
				top: rem(10px);
				right: calc(100% + 1rem);
				opacity: 0.25;
			}
		}
	}


	&.headroom--top {

		.home & {

			.header__content {

				.button {
					@extend .is-white-l;
				}
			}
		}
	}

	&.headroom--not-top {

		.home & {

			> .container {

				&:after,
				&:before {
					opacity: 1;
				}
			}

			.header__scroll {
				opacity: 0;
			}

			.title {
				color: $color-primary;
			}

			.header__content {

				.video-link {
					color: $color-primary;
				}
			}

			@include breakpoint(l) {

				&:before {
					background-position: left bottom;
				}

				.header__label {
					opacity: 0;
				}

				.header__page-name {

					.button {
						opacity: 1;
					}
				}
			}
		}
	}
}
