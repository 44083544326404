@mixin responsive-container-psudo($width, $height, $background: transparent) {
	position: relative;
	overflow: hidden;

	&:before {
		position: relative;
		display: block;
		content: '';
		padding: 0 0 (100% * $height / $width);
		background-color: $background;
	}

	img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}
}
