﻿.contact-links {

	&.is-primary {

		.contact-link {
			color: $color-primary;

			&:hover {
				color: $color-secondary;
			}
		}
	}
}

.contact-link {
	@include font-title-contact;
	position: relative;
	display: block;
	padding: 0 0 0 rem(40px);
	margin: 0 0 rem(20px);
	font-weight: bold;
	transition: color $transition-time ease-in-out;
	font-weight: 900;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		font-size: rem(24px);
		color: $color-primary;
	}

	&.is-telephone {

		&:before {
			@include icon($icon-phone);
		}
	}

	&.is-email {

		&:before {
			@include icon($icon-mail);
		}
	}

	&.is-linkedin {

		&:before {
			@include icon($icon-linkedin);
			top: rem(-3px);
		}
	}

	&:hover {
		color: $color-primary
	}
}
