ul.social-media {
	overflow: hidden;
	padding: 0;
	margin: 0 rem(-7.5px);
	list-style: none;

	li {
		display: inline-block;
		margin: 0 rem(15px);
	}

	a {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		min-height: rem(32px);
		/*width: rem(60px);
		height: rem(60px);*/
		color: $color-secondary;
		font-size: rem(24px);
		text-align: center;
		text-decoration: none;
		transition: all $transition-time ease-in-out;

		i {
			position: relative;
			top: rem(-4px);
		}

		&:hover,
		&:focus {
			color: $color-primary;
		}
	}

	&.is-primary {

		a {
			color: $color-primary;

			&:hover,
			&:focus {
				color: $color-secondary;
			}
		}
	}

	&.is-in-footer {

		a {
			color: $color-primary;

			&:hover,
			&:focus {
				color: #fff;
			}
		}
	}
}
