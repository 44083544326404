﻿.short-description {
	position: relative;
	@include font-short-description;
	text-transform: uppercase;
	color: $color-secondary;
	font-weight: normal;

	&.has-decoration {

		&:before {
			position: absolute;
			content: '';
			width: rem(16px);
			height: rem(16px);
			border: 1px solid $color-secondary;
			border-radius: 50%;
			bottom: rem(35px);
			left: 0;
		}

		&:after {
			position: absolute;
			content: '';
			height: rem(95px);
			width: 1px;
			bottom: rem(51px);
			left: rem(8px);
			background: $color-secondary;
		}

		&.is-centerd {

			&:before {
				left: 50%;
				transform: translateX(-50%);
			}

			&:after {
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
}
