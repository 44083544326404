﻿.highlighted-list {
	max-width: rem(572px);
	padding: 0;
	list-style: none;
	color: $color-primary;
	font-weight: bold;

	.highlighted-list__item {
		position: relative;
		padding: rem(9px) rem(48px) rem(12px);
		border-bottom: 1px solid rgba($color-primary, 0.1);

		&:first-child {
			border-top: 1px solid rgba($color-primary, 0.1);
		}

		&:before {
			@include icon($icon-list-icon);
			position: absolute;
			top: rem(10px);
			left: 0;
			font-size: rem(24px);
			color: $color-special;
		}
	}
}
