﻿.footer-content {
	position: relative;
	z-index: 0;
	color: #fff;
	padding: rem(21px) 0 0;

	@include breakpoint(l) {
		padding: rem(21px) 0 rem(52px);
	}

	.column {
		margin: 0 0 rem(68px);
	}

	.title {
		position: relative;
		color: #fff;
		text-transform: none;
		padding: 0 0 rem(19px);
		margin: 0 0 rem(19px);

		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 1px;
			bottom: 0;
			left: 0;
			background: rgba(#fff, 0.2);
		}
	}

	.container {
		margin: 0 auto;
	}

	.columns {
		justify-content: center;
	}

	.footer-content__list {
		list-style: none;
		padding: 0;
	}

	.footer-content__list-link {
		position: relative;
		display: inline-block;
		padding: 0 rem(29px) 0 0;
		margin: 0 0 rem(23px);
		transition: color $transition-time ease-in-out;

		&:after {
			@include icon($icon-arrow-right);
			position: absolute;
			top: rem(4px);
			right: rem(3px);
			font-size: rem(16px);
			transition: right $transition-time ease-in-out;
		}

		&:hover {
			color: $color-primary;

			&:after {
				right: rem(0);
			}
		}
	}

	.footer__link {
		position: relative;
		display: block;
		padding: 0 0 0 rem(40px);
		margin: 0 0 rem(20px);
		font-weight: bold;
		transition: color $transition-time ease-in-out;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			font-size: rem(24px);
			color: $color-primary;
		}

		&.is-telephone {

			&:before {
				@include icon($icon-phone);
			}
		}

		&.is-email {

			&:before {
				@include icon($icon-mail);
			}
		}

		&:hover {
			color: $color-primary
		}
	}
}
