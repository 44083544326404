.shortcut {
	display: block;
	position: relative;
	z-index: 0;
	margin: 0 0 rem(48px);

	.shortcut__content {
		padding: rem(41px) rem(32px) rem(48px);
		background: $color-primary;
		transition: background $transition-time ease-in-out;
	}

	.title {
		margin: 0 0 rem(32px);
		color: #fff;
	}

	&:after {
		position: absolute;
		z-index: -1;
		content: '';
		width: 100%;
		height: 100%;
		top: rem(8px);
		left: rem(8px);
		background: rgba($color-primary, 0.2);
	}

	&:hover {

		.shortcut__content {
			background: mix($color-secondary, $color-primary);
		}
	}
}
