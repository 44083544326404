.slide {
	text-align: left;
	padding: rem(58px) 0 0;

	.short-description {
		margin: 0 0 rem(12px);
	}

	.sub-title {
		margin: 0 0 rem(18px);
	}

	.video-link {
		margin: 0 0 rem(40px);
	}

	&.is-feature-slide {

		.content-block__content {
			padding: rem(50px) 0 0;
		}
	}
}
