﻿.image {

	&.is-auto-height {
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	&.is-square {
		@include responsive-container-psudo(1, 1);
	}

	&.is-3by2 {
		@include responsive-container-psudo(3, 2);
	}

	&.is-800by705 {
		@include responsive-container-psudo(800, 705);
	}

	&.is-image {
		@include responsive-container-psudo(1136, 757);
	}

	&.has-shadow {
		position: relative;
		z-index: 0;
		overflow: visible;

		&:after {
			position: absolute;
			z-index: -1;
			content: '';
			width: 100%;
			height: 100%;
			top: rem(8px);
			left: rem(8px);
			background: rgba($color-primary, 0.2);
		}
	}

	&.has-placeholder {
		position: relative;
		z-index: 0;

		&:after {
			position: absolute;
			z-index: -2;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: url(../../images/logo-white.svg) $color-primary no-repeat center;
			background-size: rem(141px) rem(48px);
		}
	}

	&.has-full-height {
		position: relative;
		height: 100%;

		@include breakpoint("l") {
			width: 100%;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
		}
	}

	&.is-video {
		position: relative;
		cursor: pointer;

		a[rel=modal] {

			&:after {
				@include icon($icon-play);
				position: absolute;
				width: rem(64px);
				height: rem(64px);
				padding: rem(20px) 0 0 rem(4px);
				border-radius: 50%;
				top: 50%;
				left: 50%;
				font-size: rem(24px);
				background-color: $color-secondary;
				color: #fff;
				text-align: center;
				transition: all $transition-time ease-in-out;
				transform: translate(-50%, -50%) scale(1);
			}

			&:focus {

				&:after {
					transform: translate(-50%, -50%) scale(1.2);
				}
			}

			&:hover {

				&:after {
					transform: translate(-50%, -50%) scale(1.2);
				}
			}
		}

		&.is-large {

			a[rel=modal] {

				&:after {

					@include breakpoint(t) {
						width: rem(128px);
						height: rem(128px);
						padding: rem(40px) 0 0 rem(4px);
						font-size: rem(47px);
					}
				}
			}
		}
	}

	&.has-hover {
		overflow: hidden;

		img {
			transform: scale(1);
			transition: transform $transition-time ease-out;
		}

		&:hover {

			img {
				transform: scale(1.2);
			}
		}
	}

	img {
		width: 100%;
		height: auto;
	}
}

.images {
	margin-top: rem(48px);

	.image {
		margin-bottom: rem(48px);
	}
}