﻿.highlighted-block-item {
	position: relative;
	padding: 0 0 0 rem(62px);

	.sub-title {
		color: #fff;
		margin: 0 0 rem(7px);
	}

	i {
		position: absolute;
		top: rem(4px);
		left: 0;
		font-size: rem(36px);
		color: $color-primary;
	}
}
