﻿.link {
	@include font-title-button;
	position: relative;
	z-index: 0;
	display: inline-flex;
	border: none;
	padding: 0 rem(23px) rem(4px) 0;
	margin: 0;
	text-decoration: none;
	color: $color-secondary;
	overflow: hidden;
	cursor: pointer;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: bold;
	background: none;
	transition: color $transition-time ease-in-out;


	&:before,
	&:after {
		position: absolute;
		z-index: -1;
		content: '';
		width: 100%;
		height: rem(2px);
		bottom: 0;
		left: 0;
	}

	&:before {
		background: $color-secondary;
	}

	&:after {
		background: $color-primary;
		transform: scaleX(0);
		transform-origin: left;
		transition: transform $transition-time ease-in-out, opacity $transition-time ease-in-out;
		opacity: 1;
	}

	&.is-centerd {
		padding: 0 rem(23px) rem(4px) rem(23px);
	}

	&.is-white {
		color: #fff;

		&:before {
			background: #fff;
		}
	}

	&.is-primary {
		color: $color-primary;

		&:before {
			background: $color-primary;
		}

		&:after {
			background: $color-secondary;
		}

		&.has-white-hover:after {
			background: #fff;
		}
	}

	&.is-loading {
		padding-left: 60px;

		&:after {
			position: absolute;
			display: block;
			content: '';
			border: rem(4px) solid $color-secondary;
			border-radius: 50%;
			border-top: rem(4px) solid lighten($color-secondary, 10%);
			width: rem(20px);
			height: rem(20px);
			top: rem(16px);
			left: rem(16px);
			animation: spin 1.5s linear infinite;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	&:focus {
		@extend .link-focus;
	}

	&:hover {
		@extend .link-hover;
	}
}

.link-focus {

	&:after {
		transform: scaleX(1);
	}
}

.link-hover {
	color: $color-primary;

	&:after {
		transform: scaleX(1);
		opacity: 1;
	}

	&.is-primary {
		color: $color-secondary;
	}

	&.is-primary.has-white-hover {
		color: #fff;
	}
}

.links {
	margin: rem(-10px);

	.link {
		margin: rem(10px);
	}
}