﻿#nav-toggler {
	display: none;

	&:checked ~ .fixed-content .hamburger {
		@extend .is-active;
	}

	&:checked ~ .nav.is-side-nav {
		opacity: 1;
		transform: scaleY(1);
		height: 100vh;
	}
}

.nav.is-side-nav {
	//@include font-title-small;
	position: fixed;
	z-index: 1000;
	width: calc(100% + var(--sw));
	height: 0;
	padding: 0;
	top: 0;
	left: 0;
	opacity: 0;
	background: #fff;
	color: $color-primary;
	transition: height $transition-time ease-in-out, opacity $transition-time ease-in-out;
	font-weight: bold;
	//transform: scaleY(0);
	overflow: hidden;

	.has-no-overflow & {
		width: 100%;
	}

	.nav-wrapper__image {
		z-index: -2;
		position: absolute;
		width: 60%;
		height: 100%;
		top: 0;
		left: 0;
		display: none;
		object-fit: cover;
		object-position: center;

		@include breakpoint(l) {
			display: block;
		}
	}

	@include breakpoint(l) {

		&:before {
			position: absolute;
			content: '';
			z-index: -1;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: #fff;
			clip-path: polygon(40% 0, 100% 0, 100% 100%, 28% 100%);
		}
	}

	@include breakpoint(lm) {

		&:before {
			clip-path: polygon(56% 0, 100% 0, 100% 100%, 28% 100%);
		}
	}

	.nav-wrapper {
		position: relative;
		z-index: 0;
		height: 100vh;
		overflow-y: auto;
	}

	.container {
		display: flex;
		margin: 0 auto;
		justify-content: flex-end;
		padding: 0 rem(20px) rem(100px);
	}

	.nav-content {
		max-width: rem(572px);
		width: 100%;
		padding: rem(214px) 0 rem(100px);
	}

	.short-description {
		display: block;
		margin: 0 0 rem(8px);
	}

	.nav-container {
		@include font-title;
		list-style: none;
		font-weight: bold;
		padding: 0 0 rem(20px);
		border-bottom: 1px solid rgba($color-primary, 0.1);

		> li {

			> a {
				position: relative;
			}

			label {
				position: absolute;
				z-index: 2;
				width: rem(48px);
				height: rem(48px);
				top: 0;
				right: 0;
				cursor: pointer;
				background: #0a508c;

				i:before {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: rem(10px);
					transition: transform $transition-time ease-in-out;
					color: $color-secondary;
				}

				&:hover {

					i:before {
						transform: translate(-50%, -50%) rotate(-90deg);
					}
				}
			}
		}

		li {
			position: relative;
			padding: 0;
			margin: 0 0 rem(24px);

			input {
				display: none;

				&:checked ~ label i:before {
					//@include icon($icon-arrow-clean-up);
				}

				&:checked ~ ul {
					display: block;
				}
			}

			&.is-active > a > span {
				//@include underline-effect-hover();
			}

			ul {
				//@include font-button;
				padding: 0;
				list-style: none;
				display: none;
				opacity: 0.7;

				li:first-child a {
					padding: rem(17px) rem(70px) rem(13px) rem(10px);
				}

				a {
					padding: rem(11px) rem(70px) rem(13px) rem(10px);
					border: none;
				}
			}
		}

		a {
			display: block;
			padding: 0;
			transition: all $transition-time ease-in-out;
			text-transform: uppercase;
			letter-spacing: 0.05em;

			span {
				@include underline-effect-base($color-primary, 1px);
			}

			&:focus span,
			&:hover span {
				@include underline-effect-hover();
			}
		}
	}

	.columns {
		min-height: 100vh;
	}

	.nav__contact-container {
		display: flex;
		flex-wrap: wrap;
		background: $color-primary;
		color: #fff;
		padding: 0 rem(20px);
		font-weight: normal;

		@include breakpoint(l) {
			padding: 0 rem(50px);
			order: -1;
		}

		@include breakpoint(lm) {
			padding: 0 rem(100px);
		}

		.nav-contact__item {
			display: block;
		}

		.social-media {
			margin-top: rem(48px);
		}

		.logo {
			left: rem(30px);
			display: none;

			@include breakpoint(l) {
				display: block;
			}

			@include breakpoint(lm) {
				padding: 0 rem(100px);
				left: rem(-20px);
			}
		}
	}

	.nav__contact {
		margin-top: auto;
		padding: rem(50px) 0;

		@include breakpoint(l) {
			padding: 0 0 rem(60px);
		}
	}
}