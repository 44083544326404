.call-to-action {
	position: relative;
	z-index: 0;
	background: $color-secondary;
	padding: rem(90px) 0 rem(96px);
	margin: 0 0 rem(120px);

	.container {
		margin: 0 auto;
	}

	.title {
		max-width: rem(720px);
		color: #fff;
		margin: 0 0 rem(41px);

		strong:before {
			background: #fff;
		}
	}

	&:before,
	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&:before {
		z-index: -2;
		background: url(../../images/background-element-white.svg) 0 rem(-37px);
		background-size: rem(45px) rem(74px);
	}

	&:after {
		z-index: -1;
		background: linear-gradient(90deg, rgba(29,186,223,1) 0%, rgba(29,186,223,0) 100%);
	}

	&:last-child {
		margin: 0; 
	}
}
