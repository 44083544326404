﻿.input,
.form-cell input {
	width: 100%;
	min-height: rem(46px);
	padding: 0 rem(20px);
	border: 1px solid rgba($color-primary, 0.1);
	overflow: hidden;
	color: #000;
	transition: all $transition-time ease-in-out;
	line-height: rem(46px);

	&:focus {
		border-color: rgba($color-primary, 0.25);
	}
}
