﻿.dropdown-list {
	position: relative;

	dt {
		outline: none;
		cursor: pointer;
	}

	dd {
		position: absolute;
		overflow: hidden;
		width: 100%;
		top: calc(100% -1px);
		list-style: none;
		visibility: hidden;
	}

	&:not(.clicked):focus-within,
	&.clicked.open {

		dd {
			visibility: visible;
		}
	}
}