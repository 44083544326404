﻿.to-top {
	@include font-title-button;
	display: block;
	position: absolute;
	padding: rem(20px) rem(32px) rem(29px) rem(76px);
	bottom: 0;
	right: 0;
	background: $color-secondary url(../../images/background-element-white.svg) rem(12px) rem(-40px);
	background-size: rem(45px) rem(74px);
	color: #fff;
	font-weight: bold;
	clip-path: polygon(33px 0, 100% 0, 100% 100%, 0 100%);
	transition: background $transition-time ease-in-out;

	i {
		position: absolute;
		top: rem(14px);
		left: rem(52px);
		transition: top $transition-time ease-in-out;
	}

	&:hover {

		i {
			top: rem(11px);
		}
	}
}
